import Tshirt705 from '../assets/images/705Brave.webp'
import stayedi from '../assets/images/haryosetyadi.webp'
import FaithYarn from '../assets/images/faith-yarn.webp'
import Tshirts705 from '../assets/images/t-shirt705.webp'

export const ProductCategory = {
    Shirts: "shirts",
    Pants: "pants",
    Shoes: "shoes",
  };
  


  
  export const products = [
    {
      id: 32,
      name: "705 Brave",
      slug: "t-705-shirt-brave",
      price: [799,1199,1699],
      description:
        "Stay Brave with the 705 Shirt from Ryan Hoffman Collection.",
      // cover: [stayedi,Tshirt705,stayedi],
      cover:{
        Tshirt:[stayedi,stayedi,stayedi],
        FullSleve:[Tshirt705,Tshirt705,Tshirt705],
        Hoodie:[stayedi,stayedi,stayedi]

      },
      // "https://astro-shop-ten.vercel.app/products/ryan-hoffman-A7f7XRKgUWc-unsplash.webp",
      coverCredits:
        "https://unsplash.com/photos/A7f7XRKgUWc?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
      category: ProductCategory.Shirts,
    },
    {
      id: 18,
      name: "Haryo Setyadi",
      slug: "haryo-setyadi-shirt",
      price: [799,1199,1699],

      description:
        "Feel the Comfort and Peace of the Haryo Setyadi Shirt white as snow.",
      // cover: [stayedi,Tshirt705,stayedi],
      cover:{
        Tshirt:[stayedi,stayedi,stayedi],
        FullSleve:[Tshirt705,Tshirt705,Tshirt705],
        Hoodie:[stayedi,stayedi,stayedi]

      },
      // "https://astro-shop-ten.vercel.app/products/haryo-setyadi-acn5ERAeSb4-unsplash.webp",
      coverCredits:
        "https://unsplash.com/photos/acn5ERAeSb4?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
      category: ProductCategory.Shirts,
    },
    {
      id: 21,
      name: "Faith Yarn Shirt",
      slug: "faith-yarn-shirt",
      price: [799,1199,1699],

      description:
        "Elevate Your Style and Comfort with the Faith Yarn Shoes. Designed to stay cool and dry all day.",
      
      cover:{
        Tshirt:[FaithYarn,FaithYarn,FaithYarn],
        FullSleve:[Tshirt705,Tshirt705,Tshirt705],
        Hoodie:[stayedi,stayedi,stayedi]

      },
      // "https://astro-shop-ten.vercel.app/products/faith-yarn-Wr0TpKqf26s-unsplash.webp",
      coverCredits:
        "https://unsplash.com/photos/Wr0TpKqf26s?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
      category: ProductCategory.Shirts,
    },
    {
      id: 49,
      name: "T-Shirt 705",
      slug: "t-shirt-32",
      price: [799,1199,1699],

      description:
        "Stay Cool and Dry with the T-Shirt 705 from Ryan Hoffman Collection. Relax, run, dream.",
      // cover:[Tshirts705,stayedi,Tshirt705],
      cover:{
        Tshirt:[FaithYarn,FaithYarn,FaithYarn]
        // [stayedi,stayedi,stayedi]
        // [Tshirts705,Tshirt705,Tshirt705]
        ,
        // FullSleve:[stayedi,stayedi,stayedi],
        FullSleve:[Tshirt705,Tshirt705,Tshirt705],

        Hoodie:[Tshirt705,Tshirt705,Tshirt705]

      },
      //  "https://astro-shop-ten.vercel.app/products/ryan-hoffman-6Nub980bI3I-unsplash.webp",
      coverCredits:
        "https://unsplash.com/photos/6Nub980bI3I?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
      category: ProductCategory.Shirts,
    },
    {
      id: 3,
      name: "Brave",
      slug: "brave",
      price: [799,1199,1699],

      description:
        "Stay Brave with the 705 Shirt from Ryan Hoffman Collection.",
      // cover: [Tshirt705,stayedi,Tshirt705],
      cover:{
        Tshirt:
        // [Tshirts705,Tshirt705,Tshirt705]
        [stayedi,stayedi,stayedi]
        ,
        FullSleve:
        // [stayedi,stayedi,stayedi]
        [Tshirts705,Tshirt705,Tshirt705]

        ,
        Hoodie:[Tshirt705,Tshirt705,Tshirt705]

      },
      // "https://astro-shop-ten.vercel.app/products/ryan-hoffman-A7f7XRKgUWc-unsplash.webp",
      coverCredits:
        "https://unsplash.com/photos/A7f7XRKgUWc?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
      category: ProductCategory.Shirts,
    },
    {
      id: 4,
      name: "T-705",
      slug: "shirt-32",
      price: [799,1199,1699],

      description:
        "Stay Cool and Dry with the T-Shirt 705 from Ryan Hoffman Collection. Relax, run, dream.",
      // cover:[Tshirts705,stayedi,Tshirt705],
      cover:{
        Tshirt:
        // [Tshirts705,Tshirt705,Tshirt705]
        // [stayedi,stayedi,stayedi]
        [FaithYarn,FaithYarn,FaithYarn]

        ,
        FullSleve:
        [Tshirts705,Tshirt705,Tshirt705]

        // [stayedi,stayedi,stayedi]
        ,
        Hoodie:[Tshirt705,Tshirt705,Tshirt705]

      },
      //  "https://astro-shop-ten.vercel.app/products/ryan-hoffman-6Nub980bI3I-unsplash.webp",
      coverCredits:
        "https://unsplash.com/photos/6Nub980bI3I?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
      category: ProductCategory.Shirts,
    },
    // {
    //   id: 1,
    //   name: "Haretyadi",
    //   slug: "haryo-setyadi-shirt",
    //   price: 199,
    //   description:
    //     "Feel the Comfort and Peace of the Haryo Setyadi Shirt white as snow. Don't worry about the stain, it's easy to clean.",
    //   cover: stayedi,
    //   // "https://astro-shop-ten.vercel.app/products/haryo-setyadi-acn5ERAeSb4-unsplash.webp",
    //   coverCredits:
    //     "https://unsplash.com/photos/acn5ERAeSb4?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
    //   category: ProductCategory.Shirts,
    // },
    // {
    //   id: 2,
    //   name: "Faith T-Shirt",
    //   slug: "faith-yarn-shirt",
    //   price: 59,
    //   description:
    //     "Elevate Your Style and Comfort with the Faith Yarn Shoes. Designed to stay cool and dry all day.",
    //   cover: FaithYarn,
    //   // "https://astro-shop-ten.vercel.app/products/faith-yarn-Wr0TpKqf26s-unsplash.webp",
    //   coverCredits:
    //     "https://unsplash.com/photos/Wr0TpKqf26s?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
    //   category: ProductCategory.Shirts,
    // },
  
   
    
    
  ];
  