import React from "react";
import { SlArrowRight } from "react-icons/sl";

import { TfiClose } from "react-icons/tfi";
import ShirtMeasureImage from "../assets/printsAssets/shirtSizeMeasure.png";
export default function Modal({storedBGColor}) {
  const [showModal, setShowModal] = React.useState(false);

  const labels = ["SIZE", "CHEST", "LENGTH"];
  const sizes = ["XS", "S", "M", "L", "XL", "XXL"];
  const chestMeasurements = [36, 38, 40, 42, 44, 46];
  const lengthMeasurements = [25, 26, 27, 28, 29, 30];

  return (
    <>
      <p
        className={`cursor-pointer flex -2 font-satoshi font-regular 
          text-[18px] leading-[24.3px] text-[#383838]
              relative right-2 opacity-85 hover:opacity-100
              
                ${storedBGColor === "Charcoal_1" ? 
                          "text-[#D6D6D6]":
                          storedBGColor === "dark_shade_gary" ? 
                          "text-[#D6D6D6]":""
                        }
              
              
              `}
        onClick={() => setShowModal(true)}
      >
        Size chart
        <span
          className={`text-[18px] font-satoshi font-regular 
                             text-[#383838] py-[4px] mx-[-4px]  
                             leading-[23.46px] absolute left-[84px] cursor-pointer
                         ${storedBGColor === "Charcoal_1" ? 
                          "text-[#D6D6D6]":
                          storedBGColor === "dark_shade_gary" ? 
                          "text-[#D6D6D6]":""
                          
                        }   
                             
                             
                             
                             `}
        >
          <SlArrowRight />
        </span>
      </p>
      {showModal ? (
        <>
          <div className="flex justify-center py-4  overflow-y-auto absolute inset-0 z-50 outline-none focus:outline-none">
            <div className="relative">
              <div className="border-0 rounded-[8px]  shadow-lg relative flex flex-col w-full h-auto lg:w-[940px] lg:h-[660px] bg-white outline-none focus:outline-none">
                <div className=" flex justify-between py-3">
                  <div className="px-5"></div>
                  <div className=" flex flex-col">
                    <p className=" font-satoshi font-bold text-[22px] text-[#333333]">
                      100% Cotton T-shirts
                    </p>
                    <p className="text-center font-satoshi font-regular text-[18px] text-[#333333]">
                      Size Charts
                    </p>
                  </div>
                  <div className="px-3  flex justify-center">
                    <div className="hover:bg-gray-200  w-9 h-9 cursor-pointer  flex justify-center items-center hover:rounded-full ">
                    <TfiClose
                      className="w-[14px] h-[14px]  cursor-pointer"
                      onClick={() => setShowModal(false)}
                    />
                    </div>
                  </div>
                </div>
                {/* <div className="absolute ml-[962px] top-[24px] cursor-pointer">
                     <TfiClose
                       className="w-[14px] h-[14px] "
                       onClick={() => setShowModal(false)}
                     />
                   </div> */}
                <hr className="border-1  border-[#333333]" />

                <div className="relative flex flex-col justify-center items-center">
                  <div className=" py-8">
                    <div className=" w-full lg:w-[781px] h-full lg:h-[210px] ">
                      <div className=" flex gap-2">
                        <p className="font-satoshi text-[#333333]  text-[18px] font-semiBold">
                          Inches
                        </p>
                        <span className="pt-[0.8px]  font-satoshi text-[#333333] text-opacity-60 text-[18px]">
                          |
                        </span>
                        <p className="font-satoshi text-[#333333] text-opacity-60 pt-[0.8px]  text-[18px] font-regular">
                          CM
                        </p>
                      </div>

                      <div className="flex  gap-1 py-3 w-full">
                        <table className="border border-[#000000] text-center w-[216px]">
                          <tbody>
                            {labels?.map((item, index) => {
                              return (
                                <tr>
                                  <td
                                    key={index}
                                    className="border border-[#000000] px-4 py-2 font-satoshi font-bold text-[22px]"
                                  >
                                    {item}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        <table className="border border-[#000000] w-full text-center border-collapse border-spacing-2  lg:w-[561px] h-[150px]">
                          <thead>
                            <tr>
                              {sizes?.map((sizesItem, index) => {
                                return (
                                  <th
                                    key={index}
                                    className="border-[1px] border-[#000000] px-4 py-2 text-[22px] font-satoshi font-bold"
                                  >
                                    {sizesItem}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {chestMeasurements?.map(
                                (chestMeasureItem, index2) => {
                                  return (
                                    <td
                                      key={index2}
                                      className="border border-[#000000] px-4 text-[22px] font-satoshi font-regular text-[#333333] text-opacity-80"
                                    >
                                      {chestMeasureItem}
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                            <tr>
                              {lengthMeasurements?.map(
                                (lengthMesaureItem, index3) => {
                                  return (
                                    <td
                                      key={index3}
                                      className="border border-[#000000] px-4 text-[22px] font-satoshi font-regular text-[#333333] text-opacity-80"
                                    >
                                      {lengthMesaureItem}
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className=" font-satoshi font-regular text-[#333333] text-[18px]">
                        TIP : IF YOU DONT FIND AN EXACT MATCH, GO FOR THE NEXT
                        SIZE
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col lg:flex-row  py-8 w-full lg:w-[781px]">
                    <div className="px-16 lg:px-0 w-full lg:w-[487px] h-auto">
                      <p className="pb-6 font-satoshi font-bold text-[#333333] text-[20px] lg:text-[22px] leading-[22px]">
                        HOW TO MEASURE?
                      </p>
                      <p className="pb-4 font-satoshi font-regular text-[18px] lg:text-[22px] text-[#383838] ">
                        CHEST :
                        <span className="mx-2  font-satoshi font-regular text-[#383838] text-opacity-70 text-[18px] lg:text-[22px] ">
                          Measure from the stitches below the armpits on one
                          side to another
                        </span>
                      </p>
                      <p className="pb-4  w-[460px] font-satoshi font-regular text-[18px] lg:text-[22px] text-[#383838]">
                        LENGTH :
                        <span className="mx-2 font-satoshi font-regular text-[#383838] text-opacity-70 text-[18px] lg:text-[22px]">
                          Measure from where the shoulder seam meets the collar
                          to the hem
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-center items-center flex-col lg:flex">
                      <img
                        src={ShirtMeasureImage}
                        alt="Shirt Measure Image"
                        className="w-[200px] h-auto lg:w-[269px] lg:h-[235px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
