import React, { useEffect, useState } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import FrameImage5 from "../assets/printsAssets/Frame 5.png";
import FrameImage6 from "../assets/printsAssets/Frame 6.png";
import FrameImage7 from "../assets/printsAssets/Frame 7.png";
import FrameImage9 from "../assets/printsAssets/Frame 9.png";
import FrameImage10 from "../assets/printsAssets/Frame 10.png";
import FrameImage14 from "../assets/printsAssets/Frame 14.png";
import FrameImage16 from "../assets/printsAssets/Frame 16.png";
import FrameImage17 from "../assets/printsAssets/Frame 17.png";
import FrameImage18 from "../assets/printsAssets/Frame 18.png";

import FrameImage20 from "../assets/printsAssets/Frame 20.png";
import FrameImage21 from "../assets/printsAssets/Frame 21.png";
import FrameImage22 from "../assets/printsAssets/Frame 22.png";
import FrameImage23 from "../assets/printsAssets/Frame 23.png";
import FrameImage24 from "../assets/printsAssets/Frame 24.png";
import FrameImage26 from "../assets/printsAssets/Frame 26.png";
import FrameImage27 from "../assets/printsAssets/Frame 27.png";
import FrameImage28 from "../assets/printsAssets/Frame 28.png";
import FrameImage29 from "../assets/printsAssets/Frame 29.png";
import FrameImage33 from "../assets/printsAssets/Frame 33.png";
import FrameImage34 from "../assets/printsAssets/Frame 34.png";
import FrameImage35 from "../assets/printsAssets/Frame 35.png";
import FrameImage36 from "../assets/printsAssets/Frame 36.png";
import FrameImage37 from "../assets/printsAssets/Frame 37.png";
import FrameImage63 from "../assets/printsAssets/Frame 63.png";
import FrameImage65 from "../assets/printsAssets/Frame 65.png";
import FrameImage66 from "../assets/printsAssets/Frame 66.png";
import FrameImage67 from "../assets/printsAssets/Frame 67.png";
import FrameImage68 from "../assets/printsAssets/Frame 68.png";
import FrameImage69 from "../assets/printsAssets/Frame 69.png";
import FrameImage70 from "../assets/printsAssets/Frame 70.png";
import FrameImage71 from "../assets/printsAssets/Frame 71.png";
import FrameImage72 from "../assets/printsAssets/Frame 72.png";
import FrameImage73 from "../assets/printsAssets/Frame 73.png";
import FrameImage74 from "../assets/printsAssets/Frame 74.png";
import FrameImage75 from "../assets/printsAssets/Frame 75.png";
import FrameImage76 from "../assets/printsAssets/Frame 76.png";
import FrameImage77 from "../assets/printsAssets/Frame 77.png";
import FrameImage78 from "../assets/printsAssets/Frame 78.png";
import FrameImage79 from "../assets/printsAssets/Frame 79.png";
import FrameImage81 from "../assets/printsAssets/Frame 81.png";
import FrameImage82 from "../assets/printsAssets/Frame 82.png";
import FrameImage83 from "../assets/printsAssets/Frame 83.png";
import FrameImage84 from "../assets/printsAssets/Frame 84.png";
import FrameImage85 from "../assets/printsAssets/Frame 85.png";
import FrameImage86 from "../assets/printsAssets/Frame 86.png";
import FrameImage87 from "../assets/printsAssets/Frame 87.png";
import FrameImage88 from "../assets/printsAssets/Frame 88.png";
import FrameImage89 from "../assets/printsAssets/Frame 89.png";
import FrameImage90 from "../assets/printsAssets/Frame 90.png";
import FrameImage91 from "../assets/printsAssets/Frame 91.png";
import FrameImage92 from "../assets/printsAssets/Frame 92.png";
import FrameImage93 from "../assets/printsAssets/Frame 93.png";
import FrameImage94 from "../assets/printsAssets/Frame 94.png";
import FrameImage95 from "../assets/printsAssets/Frame 95.png";
import FrameImage96 from "../assets/printsAssets/Frame 96.png";
import FrameImage97 from "../assets/printsAssets/Frame 97.png";
import FrameImage98 from "../assets/printsAssets/Frame 98.png";
import FrameImage99 from "../assets/printsAssets/Frame 99.png";
import FrameImage100 from "../assets/printsAssets/Frame 100.png";
import FrameImage101 from "../assets/printsAssets/IMG_2103.png";
// import './print.css'
import { motion, AnimatePresence } from "framer-motion";

import LeftArrow from "../assets/printsAssets/vectorLeft.png";
import RightArrow from "../assets/printsAssets/vectorRight.png";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import PositionDesign1 from "../assets/printsAssets/t-shirtprintpositionAssest/Group 18.png";
import PositionDesign2 from "../assets/printsAssets/t-shirtprintpositionAssest/Group 18.png";
import PositionDesign3 from "../assets/printsAssets/t-shirtprintpositionAssest/Group 19.png";

const images = [
  FrameImage5,
  FrameImage6,
  FrameImage7,
  FrameImage9,
  FrameImage10,
  FrameImage14,
  FrameImage16,
  FrameImage17,
  FrameImage20,
  FrameImage21,
  FrameImage22,
  FrameImage23,
  FrameImage24,
  FrameImage26,
  FrameImage27,
  FrameImage28,
  FrameImage33,
  FrameImage34,
  FrameImage35,
  FrameImage36,
  FrameImage37,
  FrameImage63,
  FrameImage65,
  FrameImage66,
  FrameImage67,
  FrameImage68,
  FrameImage69,
  FrameImage70,
  FrameImage71,
  FrameImage72,
  FrameImage73,
  FrameImage74,
  FrameImage75,
  FrameImage76,
  FrameImage77,
  FrameImage78,
  FrameImage79,
  FrameImage81,
  FrameImage82,
  FrameImage83,
  FrameImage84,
  FrameImage85,
  FrameImage86,
  FrameImage87,
  FrameImage88,
  FrameImage89,
  FrameImage90,
  FrameImage91,
  FrameImage92,
  FrameImage93,
  FrameImage94,
  FrameImage95,
  FrameImage96,
  FrameImage97,
  FrameImage98,
  FrameImage99,
  FrameImage100,
  FrameImage101,
  FrameImage18,
  FrameImage29,
];

const designPositionImages = [
  PositionDesign1,
  PositionDesign2,
  PositionDesign3,
];

const ImageGallery = ({storedBGColor}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 20;

  const totalPages = Math.ceil(images.length / imagesPerPage);

  // Get the images for the current page
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const paginatedAdditionalImages = currentImages.map((_, index) => {
    const designIndex = index % designPositionImages.length; // Cycle through designs
    return designPositionImages[designIndex];
  });
  // console.log(paginatedAdditionalImages)

  // Handle pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setDirection(1);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setDirection(-1);
      setCurrentPage(currentPage - 1);
    }
  };

  const [direction, setDirection] = useState(0);

  return (
    <div className="container mx-auto py-4">
      <div className=" flex justify-between ">
        <div className={`font-satoshi font-bold text-[20px] text-[#383838]
          
           ${storedBGColor === "Charcoal_1" ? "text-[#D6D6D6]":
            storedBGColor === "dark_shade_gary" ? 
            "text-[#D6D6D6]":
            storedBGColor === "Charcoals" ? 
            "text-[#D6D6D6]":
            storedBGColor==="Dark_Purple_Gradient"?'text-[#D6D6D6] ':
            storedBGColor==="black_1"?'text-[#D6D6D6]':

          ""
      }
          
          
          
          `}>
          Prints
        </div>
        <div>
          <div className={`flex gap-2 justify-center items-center
            
            
            
            `}>
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className={`  text-[#383838] 
                
                 ${storedBGColor === "Charcoal_1" ? "text-[#D6D6D6]":
                  storedBGColor === "dark_shade_gary" ? 
                  "text-[#D6D6D6]":
                  storedBGColor === "Charcoals" ? 
                  "text-[#D6D6D6]":
                  storedBGColor==="Dark_Purple_Gradient"?'text-[#D6D6D6] ':
                  storedBGColor==="black_1"?'text-[#D6D6D6]':

          ""
      }
                
                
                rounded-[2px] h-[23px] w-[22.3px] text-2xl text-center mt-2 ${
                currentPage === 1 ? "cursor-not-allowed opacity-1" : ""
              }`}
            >
              <SlArrowLeft className="h-[23px] w-[22.3px]" />
            </button>

            <p className={` mt-2 font-satoshi font-regular text-[18px]
              
                  ${storedBGColor === "Charcoal_1" ? "text-[#D6D6D6]":
                    storedBGColor === "dark_shade_gary" ? 
                    "text-[#D6D6D6]":
                    storedBGColor === "Charcoals" ? 
                    "text-[#D6D6D6]":
                    storedBGColor==="Dark_Purple_Gradient"?'text-[#D6D6D6] ':
                    storedBGColor==="black_1"?'text-[#D6D6D6]':

          ""
      }
              
              `}>
              Page {currentPage} of {totalPages}
            </p>

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className={`  rounded-[2px] text-[#383838]
                
                    ${storedBGColor === "Charcoal_1" ? "text-[#D6D6D6]":
                      storedBGColor === "dark_shade_gary" ? 
                      "text-[#D6D6D6]":
                      storedBGColor === "Charcoals" ? 
                      "text-[#D6D6D6]":
                      storedBGColor==="Dark_Purple_Gradient"?'text-[#D6D6D6] ':
                      storedBGColor==="black_1"?'text-[#D6D6D6]':

          ""
      }
                
                h-[23px] w-[22.3px] text-2xl text-center mt-2 ${
                currentPage === totalPages ? "cursor-not-allowed opacity-1" : ""
              }`}
            >
              <SlArrowRight className="h-[23px] w-[22.3px]" />
            </button>
          </div>
        </div>
      </div>
      <motion.div
        key={currentPage}
        initial={{ x: direction === 1 ? "100% " : "-100%" }}
        animate={{ x: "0%" }}
        transition={{ duration: 0.8 }}
        className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 py-4 "
      >
        {currentImages?.map((image, index) => (
          <div
            key={index}
            className="bg-white relative flex justify-center items-center p-4 shadow-md rounded-lg overflow-hidden hover:shadow-xl transform hover:scale-105 transition-transform duration-300"
          >
            <img
              className="object-contain h-36 w-44"
              src={image}
              alt={`Image ${index + 1}`}
            />

            {/* { designPositionImages[index] &&  (
      <div className="absolute bottom-2 right-2">
        <img className="h-8 w-8" src={designPositionImages[index]} alt={`Additional image for ${index + 1}`} />
      </div>
    )} */}

            {paginatedAdditionalImages[index] && (
              <div key={index} className="absolute bottom-2 right-2">
                <img
                  className="h-8 w-8"
                  src={paginatedAdditionalImages[index]}
                  alt={`Additional image ${index + 1}`}
                />
              </div>
            )}
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default ImageGallery;
