
import React, { useEffect, useState } from 'react';
import Layout from '../layouts/layout';
import ProductCard from '../components/productCard';
import { products } from '../data/productData';
import { motion,AnimatePresence } from 'framer-motion';
import Prints from './prints'
import { NavLink } from 'react-router-dom';

const HomePage = () => {
const [storedBGColor,setBgColor]= useState(null);


useEffect(()=>{
  const bgColors= localStorage.getItem("bgcolor")
console.log(bgColors);
setBgColor(bgColors)



},[storedBGColor])


  return (
    <Layout
      title="Astro Shop"
      description="An example shop using View Transitions with Astro and TailwindCSS"
      image="/products/ryan-hoffman-A7f7XRKgUWc-unsplash.webp"
    >
      <div className='py-4'>
        <h1 className={`text-[#383838] font-black font-satoshi text-[40px] tracking-tight leading-[54px] mb-2
          ${
            storedBGColor === "Cream"?"text-[#E54C44]":
            storedBGColor === "Light_Gray"?"text-[#6B2055]":
            storedBGColor === "Light_Gray_1"?"text-[#E9185F]":
            storedBGColor === "control" ?"text-[#383838]":
            storedBGColor === "Soft_Gray"?"text-[#D94E29]":
            storedBGColor === "Muted_Beige"?"text-[#091C25]":
            storedBGColor === "Charcoal_1"?"text-[#F58F7C]":
            storedBGColor === "dark_shade_gary"?"text-[#F58F7C]":
            storedBGColor === "Charcoals"?"text-[#FE5335]":

            storedBGColor==="Dark_Purple_Gradient"?'text-[#FFFFFF]':
            storedBGColor==="black_1"?'text-[#EAA36E]':



            ""
          }
          
          `}>Astro Shop To React Shop</h1>
      <div className='mt-[-4px]'>
      <h3 className={`1text-[#383838] font-satoshi font-regular 
      text-[20px] mb-4
      
       ${storedBGColor === "Charcoal_1" ? "text-[#D6D6D6]":
        storedBGColor === "dark_shade_gary" ? 
        "text-[#D6D6D6]":  
        storedBGColor === "Charcoals" ? 
        "text-[#D6D6D6]":
        storedBGColor==="Dark_Purple_Gradient"?'text-[#D6D6D6] ':
        storedBGColor==="black_1"?'text-[#D6D6D6]':
        
        ""
      }
      
      
      
      
      
      `}>
      Shop a 100% Organic cotton T-shirts at affordable rates!
       {/* <a className="hover:underline " href="https://astro.build/">astro</a>. */}
        </h3>
      </div>
        <nav className="py-4">
         
        <span className={`text-[#383838] font-bold font-satoshi text-[20px] 
        
         ${storedBGColor === "Charcoal_1" ? "text-[#D6D6D6]":
          storedBGColor === "dark_shade_gary" ? 
          "text-[#D6D6D6]":
          storedBGColor === "Charcoals" ? 
          "text-[#D6D6D6]":
          storedBGColor==="Dark_Purple_Gradient"?'text-[#D6D6D6] ':

          storedBGColor==="black_1"?'text-[#D6D6D6]':

         
         
          ""
      }
        
        
        `}style={{lineHeight:"27px"}}>Bestsellers</span>
        <span class="text-[#A5A5A5]  text-[20px] mx-2">&#183;</span>
         <NavLink to='/' className={`text-[#A5A5A5] font-regular font-satoshi text-[20px] 
         
         ${storedBGColor === "Cream"?"hover:text-[#E54C44] hover:underline":
          storedBGColor === "Light_Gray"?"hover:text-[#6B2055] hover:underline":
          storedBGColor === "Light_Gray_1"?"hover:text-[#E9185F] hover:underline":
          storedBGColor === "control" ?"hover:text-[#383838] hover:underline":
          storedBGColor === "Soft_Gray"?"hover:text-[#D94E29] hover:underline":
          storedBGColor === "Muted_Beige"?"hover:text-[#091C25] hover:underline":
          
          storedBGColor === "Charcoal_1"?"hover:text-[#F58F7C] hover:underline":
          storedBGColor === "dark_shade_gary"?"hover:text-[#F58F7C] hover:underline":
          storedBGColor === "Charcoals"?" hover:underline hover:text-[#FE5335]":
          storedBGColor==="Dark_Purple_Gradient"?'hover:text-[#FFFFFF] hover:underline':
          storedBGColor==="black_1"?'hover:text-[#EAA36E] hover:underline':

          ''
         }
         
         
         `}>
         Tshirts</NavLink>
      <span className="text-[#A5A5A5]  text-[20px] mx-2 ">&#183;</span>
      <NavLink to='/' className={`text-[#A5A5A5] font-regular font-satoshi text-[20px] 
      tracking-tight
         
         ${storedBGColor === "Cream"?"hover:text-[#E54C44] hover:underline":
           storedBGColor === "Light_Gray"?"hover:text-[#6B2055] hover:underline":
           storedBGColor === "Light_Gray_1"?"hover:text-[#E9185F] hover:underline":
           storedBGColor === "control" ?"hover:text-[#383838] hover:underline":
           storedBGColor === "Soft_Gray"?"hover:text-[#D94E29] hover:underline":
           storedBGColor === "Muted_Beige"?"hover:text-[#091C25] hover:underline":
          
           storedBGColor === "Charcoal_1"?"hover:text-[#F58F7C] hover:underline":
           storedBGColor === "dark_shade_gary"?"hover:text-[#F58F7C] hover:underline":
           storedBGColor === "Charcoals"?" hover:underline hover:text-[#FE5335]":
           storedBGColor==="Dark_Purple_Gradient"?'hover:text-[#FFFFFF] hover:underline':
          
           storedBGColor==="black_1"?'hover:text-[#EAA36E] hover:underline':
          
           ''
         }
         
         
         `}>Full Sleeve Tshirt</NavLink>
      <span className="text-[#A5A5A5]  text-[20px] mx-2">&#183;</span>
      <NavLink to='/'
      
      className={`text-[#A5A5A5] font-regular font-satoshi text-[20px] 
         
        ${storedBGColor === "Cream"?"hover:text-[#E54C44] hover:underline":
        storedBGColor === "Light_Gray"?"hover:text-[#6B2055] hover:underline":
        storedBGColor === "Light_Gray_1"?"hover:text-[#E9185F] hover:underline":
        storedBGColor === "control" ?"hover:text-[#383838] hover:underline":
        storedBGColor === "Soft_Gray"?"hover:text-[#D94E29] hover:underline":
        storedBGColor === "Muted_Beige"?"hover:text-[#091C25] hover:underline":
        storedBGColor === "dark_shade_gary"?"hover:text-[#F58F7C] hover:underline":
         
        storedBGColor === "Charcoal_1"?"hover:text-[#F58F7C] hover:underline":
        storedBGColor === "Charcoals"?" hover:underline hover:text-[#FE5335]":
        storedBGColor==="Dark_Purple_Gradient"?'hover:text-[#FFFFFF] hover:underline':
        
        storedBGColor==="black_1"?'hover:text-[#EAA36E] hover:underline':
        
        ''
        }
        
        
        `}
      
      
      >Hoodie</NavLink>
        
        
        </nav>
      </div>
   
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-8 w-full">
        
        {products.slice(0, 4).map((product) => (
               <motion.div
               key={product.id}
               initial={{ opacity: 0, y: 60 }}
               animate={{ opacity: 1, y: -10 }}
               exit={{ opacity: 0, y: -90 }}
               transition={{ duration: 0.5 }}
             >
          <ProductCard key={product.id} product={product} />
          </motion.div>
        ))}
      </div>
    <div className=' py-6'>
    <Prints  storedBGColor={storedBGColor}/>
    </div>
   
    </Layout>
  );
};

export default HomePage;
