import React from 'react';

import ProductCard from './components/productCard';
import { Route, Router, Routes } from 'react-router-dom';
import HomePage from './pages/home';

import { products } from './data/productData';
import ProductPage from './pages/productPage';

function App() {
  return (
    <div>
    
     <Routes>
     <Route exact path="/" element={<HomePage/>} />
        <Route path="/product/:slug" element={ <ProductPage products={products} />}>
         
        </Route>
      
     </Routes>
     
    </div>
  );
}

export default App;
