


import React from 'react';
import { motion } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';

const ProductCard = ({ product }) => (
 <div>
   <NavLink to={`/product/${product.slug}`} className="flex mx-auto sm:mx-auto">
    <motion.article
      className="group  flex flex-col  bg-white shadow-sm rounded-lg overflow-hidden hover:shadow-xl hover:shadow-gray-100 transition-all w-full h-full lg:h-[450px] mx-auto"
      whileHover={{ scale: 1.05 }}
    >
      {/* Image container */}
      <div className='py-6'>
      <div className="w-full   sm:h-64 overflow-hidden ">
        <motion.img
          src={product.cover.Tshirt[0]}
          alt={product.name}
          className="object-cover mt-0 object-center w-full h-full  grayscale-[0.1] group-hover:grayscale-0 group-hover:scale-105 transition-all rounded-t-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        />
      </div>
      </div>

      {/* Product details */}
      <div className="p-4 md:p-6 flex-1 flex flex-col justify-between">
        <div>
          <motion.h3
            className="font-bold font-satoshi text-[#383838] text-base md:text-lg truncate"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {product.name}
          </motion.h3>
          <motion.p
            className="font-regular font-satoshi text-[#383838] md:text-base truncate mt-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {product.description}
          </motion.p>
        </div>
        {/* Price section */}
        <motion.div
          className="text-right mt-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <span className="font-bold font-satoshi text-[#383838] text-lg md:text-xl">₹{product.price[0]}</span>
        </motion.div>
      </div>
    </motion.article>
  </NavLink>
 </div>
);

export default ProductCard;
