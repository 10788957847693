import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom'
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";



posthog.init("phc_5sV2toNxg9H5nNzWJuCmla2QRIhx0ifLEHYpGpgGqnZ", {
  api_host:"https://us.i.posthog.com",
  person_profiles: "identified_only", 
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <PostHogProvider client={posthog}>
    <Router>
    <App />
    </Router>
    </PostHogProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
